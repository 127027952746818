<template>
  <br>
  <br>
  <table>
    <thead>
    <tr>
      <th>Data completamento</th>
      <th>Titolo</th>
      <th>Testo</th>
      <th>Raggiunto</th>
      <th>In corso</th>
      <th>Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(project, projectId) in projects" :key="projectId">
      <td>{{ formatDate(project.achievement_date) }}</td>
      <td>{{ project.title }}</td>
      <td>{{ project.text.slice(0, 50) }}...</td>
      <td>{{ project.achieved ? 'Si' : 'No' }}</td>
      <td>{{ project.inProgress ? 'Si' : 'No' }}</td>
      <td>
        <div class="action-buttons">
          <button @click="editProject(projectId)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path fill="#FFFFFF" d="M14.6,2.5c-0.3,0-0.5,0.1-0.7,0.3L2.8,13.1C2.6,13.3,2.5,13.6,2.5,14v2.5c0,0.3,0.1,0.5,0.3,0.7C2.9,17.6,3.1,17.8,3.4,17.8h2.5c0.4,0,0.7-0.1,0.9-0.3l10.3-10.3c0.4-0.4,0.4-1,0-1.4l-2.5-2.5C15.1,2.6,14.9,2.5,14.6,2.5zM4.8,14.6v-1.3l8.1-8.1,1.3,1.3L4.8,14.6zM15.7,4.7l-1.3-1.3c-0.2-0.2-0.5-0.3-0.9-0.3H12l1.3,1.3L15.7,4.7z" />
            </svg>
          </button>
        </div>
      </td>
      <td>
        <div class="action-buttons">
          <button @click="deleteProject(projectId)">
            <img :src="deleteIcon" alt="Elimina" />
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <button @click="showModal = true">Aggiungi progetto</button>

  <div v-if="showModal" class="popup">
    <div class="popup-content">
      <div class="form-group">
        <label for="titolo">Titolo</label>
        <input type="text" id="titolo" v-model="newProject.title" />
      </div>

      <div class="form-group">
        <label for="testo">Testo</label>
        <textarea id="testo" v-model="newProject.text"></textarea>
      </div>

      <div class="form-group">
        <label for="data">Data di raggiungimento</label>
        <input type="date" id="data" v-model="newProject.achievement_date" />
      </div>

      <div class="form-group">
        <label for="raggiunto-new">Raggiunto</label>
        <input type="checkbox" id="raggiunto-new" v-model="newProject.achieved" />
      </div>

      <div class="form-group">
        <label for="progress-new">In corso</label>
        <input type="checkbox" id="progress-new" v-model="newProject.achieved" />
      </div>

      <div class="button-group">
        <button @click="createProject">Crea</button>
        <button @click="showModal = false">Chiudi</button>
      </div>
    </div>
  </div>

  <!-- Aggiunta del popup per la modifica del progetto -->
  <div v-if="showEditModal" class="popup">
    <div class="popup-content">
      <div class="form-group">
        <label for="edit-titolo">Titolo</label>
        <input type="text" id="edit-titolo" v-model="editedProject.title" />
      </div>

      <div class="form-group">
        <label for="edit-testo">Testo</label>
        <textarea id="edit-testo" v-model="editedProject.text"></textarea>
      </div>

      <div class="form-group">
        <label for="edit-data">Data di raggiungimento</label>
        <input type="date" id="edit-data" v-model="editedProject.achievement_date" />
      </div>

      <div class="form-group">
        <label for="edit-raggiunto">Raggiunto</label>
        <input type="checkbox" id="edit-raggiunto" v-model="editedProject.achieved" />
      </div>

      <div class="form-group">
        <label for="edit-progress">In corso</label>
        <input type="checkbox" id="edit-progress" v-model="editedProject.inProgress" />
      </div>

      <div class="button-group">
        <button @click="updateProject">Aggiorna</button>
        <button @click="showEditModal = false">Annulla</button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/main";
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from "firebase/firestore";

export default {
  name: "ManageProjects",
  data() {
    return {
      projects: {},
      showModal: false,
      showEditModal: false, // Aggiunta della variabile per il popup di modifica
      newProject: {},
      editedProject: {}, // Aggiunta del progetto in fase di modifica
      deleteIcon: require("@/assets/icons/delete.svg")
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      this.projects[doc.id] = doc.data();
    });
  },
  methods: {
    async createProject() {
      const data = this.newProject;
      if (!data.achieved) {
        data.achieved = false;
      }
      const docRef = await addDoc(collection(db, "projects"), data);
      this.projects[docRef.id] = data;
      this.showModal = false;
      //reload the page
      this.$router.go();
    },
    editProject(id) {
      // Copia il progetto in fase di modifica
      this.editedProject = { ...this.projects[id] };
      this.editedProject.id = id;
      this.showEditModal = true;
    },
    async updateProject() {
      const id = this.editedProject.id;
      const updatedData = {
        title: this.editedProject.title,
        text: this.editedProject.text,
        achievement_date: this.editedProject.achievement_date ? this.editedProject.achievement_date : null,
        achieved: this.editedProject.achieved,
        inProgress: this.editedProject.inProgress
      };
      await updateDoc(doc(db, "projects", id), updatedData);
      this.projects[id] = updatedData;
      this.showEditModal = false;
    },
    deleteProject(id) {
      deleteDoc(doc(db, "projects", id));
      delete this.projects[id];
    },
    formatDate(date) {
      if (!date) {
        return "/";
      }
      const dateToFormat = new Date(date);
      return (
          dateToFormat.getDate() +
          "/" +
          (dateToFormat.getMonth() + 1) +
          "/" +
          dateToFormat.getFullYear()
      );
    }
  }
};
</script>

<style scoped>
/* Stili esistenti del popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group textarea {
  width: 400px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.button-group button:hover {
  background-color: #0056b3;
}

@media (max-width: 700px) {
  .popup-content {
    max-width: 400px;
  }
}
/* Nuovi stili per il popup di modifica */
#edit-titolo,
#edit-testo,
#edit-data,
#edit-raggiunto {
  width: 400px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
