<template>
  <footer class="footer">
    <div class="container">
      <div>
        <ul class="organization-info">
          <li><a>Bilbo Family Foundation Italia</a></li>
          <li><a>C.F : 92114840488</a></li>
        </ul>
      </div>

      <div class="separator"></div>

      <div>
        <ul class="social-links">
          <li>Via Antonio Angiolini 25 - Prato, PO</li>
          <li>Seguici su</li>
          <li>
            <!--<a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
              </svg>

            </a>-->
            <a href="https://instagram.com/bilbofamilyfoundationitaly">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"/></svg>
            </a>
            <a href="https://www.tiktok.com/@bilibofamily" target="_blank" class="tiktok-logo">
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 50 50">
                <path fill="#fff" d="M41 4H9C6.243 4 4 6.243 4 9v32c0 2.757 2.243 5 5 5h32c2.757 0 5-2.243 5-5V9c0-2.757-2.243-5-5-5m-3.994 18.323a7.482 7.482 0 0 1-.69.035 7.492 7.492 0 0 1-6.269-3.388v11.537a8.527 8.527 0 1 1-8.527-8.527c.178 0 .352.016.527.027v4.202c-.175-.021-.347-.053-.527-.053a4.351 4.351 0 1 0 0 8.704c2.404 0 4.527-1.894 4.527-4.298l.042-19.594h4.02a7.488 7.488 0 0 0 6.901 6.685v4.67"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar',
}
</script>

<style>
.footer {
  background: linear-gradient(90deg, #000000 0%, #DB0606 50%, #fc9700 100%);
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 10px; /* New line for rounded corners */
}

.tiktok-logo {
  position: relative;
  bottom: -1px; /* New line to move tiktok logo slightly downwards */
}

.container {
  display: flex;
  margin: 0;
  flex-direction: row;
  justify-content: center;
}

.organization-info {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex: 1;
  text-align: right;
}


.separator {
  border-left: 1px solid #fff;
  height: 100px;
  margin: 0 10px;
}

.organization-info li {
  margin-bottom: 10px;
}

.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex: 1;
  text-align: left;
}

.social-links li {
  margin-bottom: 10px;
}

.social-links a {
  margin-right: 10px;
}

a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .separator {
    height: 12vh;
  }
  li {
    font-size: 12px;
  }
}
</style>
