<template>
  <div>
    <LandingCover/>
  </div>
  <div>
    <WhoWeAre/>
  </div>
  <div>
    <EventsComponent/>
  </div>
  <div>
    <Projects/>
  </div>
  <div>
    <HowCanYouHelp/>
  </div>
  <div>
    <ContactUs/>
  </div>
</template>

<script>
import LandingCover from '@/components/LandingCover.vue'
import WhoWeAre from "@/components/WhoWeAre.vue";
import Projects from "@/components/Projects.vue";
import HowCanYouHelp from '@/components/HowCanYouHelp.vue';
import ContactUs from "@/components/ContactUs.vue";
import EventsComponent from "@/components/Events.vue";
export default {
  name: "HomeComponent",
  metaInfo: {
    title: 'Home',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'home',
        name: 'home',
        content: 'Home page di bilbo family foundation italy.'
      }
    ]
  },
  components: {
    LandingCover,
    WhoWeAre,
    Projects,
    HowCanYouHelp,
    ContactUs,
    EventsComponent
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = "Bilbo Family Foundation Italy";
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
div {
  padding-bottom: 20px;
}
</style>
