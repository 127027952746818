import { createApp } from 'vue'
import App from './App.vue'
import Meta from "vue-meta";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Home from "@/views/Home.vue";
import ManageProjects from "@/views/ManageProjects.vue";
import {createRouter, createWebHistory} from "vue-router";
import OrganizationDetails from "@/views/OrganizationDetails.vue";

const firebaseConfig = {
    apiKey: "AIzaSyDp4Jn780ZLuPni_yg7U8gDslstBROroKE",
    authDomain: "bilbofoundation.firebaseapp.com",
    databaseURL: "https://bilbofoundation-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bilbofoundation",
    storageBucket: "bilbofoundation.appspot.com",
    messagingSenderId: "958900662864",
    appId: "1:958900662864:web:32d1cece46a913e4291755",
    measurementId: "G-KS52JDWYL1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/manage",
        name: "Manage",
        component: ManageProjects,
    },
    {
        path: "/organization",
        name: "Organization",
        component: OrganizationDetails,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


createApp(App).use(router).mount('#app')
App.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})
