<template>
  <br>
  <br>
  <h2><strong>Jambo!</strong></h2>
  <p> Come direbbero i nostri fratelli ugandesi, benvenuti sulla nostra pagina.<br><br>

    La Bilbo Family Foundation Italia
    è una ODV nata dall’incontro tra Mattia Mazzeo e Jafali Mukwaya avvenuto nel Luglio del 2022.<br>

    Jafali è un
    ragazzo dell’Uganda di 26 anni con un sogno instillato dal padre venuto a mancare quando era ancora adolescente:
    dare un futuro ai tanti bambini orfani o marginalizzati del suo villaggio.<br><br>

    L’opera iniziata dal padre fu quella
    di levare dalla strada e dalle maglie dello sfruttamento minorile un piccolo gruppo di 6 bambini che abitavano la
    strada dove era sita la sua casa di famiglia.<br>


    Alla morte del padre Jafali lasciò gli studi e grazie al sostegno dell’associazione locale BIRMA del villaggio di
    Bugiri ottenne il permesso di un gruppo di circa 30<br>bambini in un piccolo appezzamento di terreno comprendente 1
    casa in semi-muratura e 3 capanne.<br><br>

    Nasceva così il primo orfanotrofio gestito da Jafali con l’aiuto di 4 delle
    madri di alcuni dei piccoli e due suoi amici Faruk e John.<br>

    In 3 anni di lavoro Jafali e il suo gruppo prende sotto
    la sua tutela un secondo gruppo di 40 bambini<br> di una remota zona nella periferia di Bugiri formando il secondo
    orfanotrofio nella località di Bukaie.<br><br>

    Mattia conosce Jafali circa un anno fa attraverso i social e nel primo mese i due ragazzi si scambiano le visioni<br>
    sui loro mondi, attraverso un telefonino Mattia mostra a Jafali il
    suo mondo e Jafali ricambia facendo lo stesso.<br>

    Al che Mattia scelse di porgere la mano che Jafali gli aveva chiesto e insieme alle persone a lui vicine iniziò a
    sostenere economicamente gli sforzi del ragazzo ugandese.<br><br>

    Il viaggio che gli congiunge fisicamente arriva nel Dicembre 2022, Mattia visita il luogo e i bambini che stava
    sostenendo e il vetro si rompe.<br>

    Dal ritorno di Mattia in Italia si forma un gruppo intenzionato a sviluppare questo ponte venutosi a creare tra
    Italia e Uganda e si decide di formare una vera e propria<br> associazione che collabori con un’associazione gemella
    sita direttamente sul territorio ugandese.<br><br>

    Nascono così la Bilbo Family Foundation (BFF) in Uganda e a circa 5200 km in linea d’aria la Bilbo Family
    Foundation Italy (BFFI).<br><br>

    Dopo i primi mesi di costituzione e attività la BFFI ha raccolto a se 21 donatori mensili e un’assemblea di 7
    soci-fondatori.<br>

    La BFF grazie al suo appoggio ha costruito 3 case in muratura e acquisito un terreno agricolo
    di 1,5 ettari, nonché dato una grande accelerata al percorso verso<br> un sostentamento alimentare e sanitario dei
    bambini, ancora tuttavia lontano dalla sua attualizzazione.
    <br/><br/>
    A Novembre 2023 siamo riusciti a raggiungere 40 donatori mensili regolari che ci aiutano a sostenere i bambini.
  </p>
  <img class="maps-image" src="@/assets/maps.png">
</template>

<script>
export default {
  name: "OrganizationDetailsComponent",
  metaInfo: {
    title: 'Dettagli organizzazione',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'organization-details',
        name: 'organization-details',
        content: 'Dettagli riguardo l\'organizzazione.'
      }
    ]
  },
  components: {
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = "Bilbo Family Foundation Italy";
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
.maps-image {
  width: 75%;
  height: 100%;
  padding-bottom: 70px;
}
</style>
