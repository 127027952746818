<template>
  <div class="container">
    <img class="landing-cover" alt="cover" lazy src="@/assets/home_landscape.gif">
    <div class="overlay"></div>
    <div class="centered">
      <h1>Bilbo Family Foundation Italy</h1>
      <h4>
        La nostra associazione nasce dalla volontà di creare un ponte che congiunga <br>
        Italia e Uganda utile a sostenere e sviluppare  una comunità<br>
        di bambini bisognosi sita nel villaggio di Bugiri.
      </h4>
      <!--<br>
      <h2>Quanto doniamo ogni mese</h2>
      <div class="funds-container">
        <div class="founders-funds">
          <h3>Fondatori</h3>
          <p>Quota donata: 250€</p>
        </div>
        <div class="separator"></div>
        <div class="supporters-funds">
          <h3>Donatori</h3>
          <p>Quota donata: 190€</p>
        </div>
      </div> -->
      <button @click="smoothScrollTo('progetti')">Scopri di più</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingCover",
  methods: {
    smoothScrollTo (id) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 100,
        behavior: 'smooth'
      });
      this.menuOpen = false;
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  background: rgba(0, 0, 0, 0.6);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landing-cover {
  object-fit:cover;
  width: 100%;
  height: 90vh;
}

.funds-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.founders-funds,
.supporters-funds {
  margin: 0 1em;
}

.separator {
  width: 1px;
  height: 4em;
  background-color: white;
}
</style>
