<template>
  <div id="eventi">
    <h2>Eventi</h2>
    <div class="content-container">
      <div class="image-and-text">
        <div class="text">
          <p>
            <strong>Cena natalizia di raccolta fondi 01/12/2023</strong>
            <br><br>
            La Bilbo Family Foundation Italy vi invita a partecipare alla nostra
            <br/>
            CENA NATALIZIA DI RACCOLTA FONDI.
            <br/>
            <br/>
            L'evento è mirato a saldare il debito scolastico che ha permesso ai nostri bambini
            <br/>
            di frequentare la scuola durante l'anno corrente e a porre le basi per il pagamento
            <br/>
            delle tasse e dei materiali scolastici che dovremo fronteggiare il prossimo anno.
          </p>
        </div>
        <span class="image">
            <img src="@/assets/event-image.jpg" alt="Image of foundation stitching" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventsComponent",
  metaInfo: {
    title: 'Eventi',
    meta: [
      {
        vmid: 'eventi',
        name: 'eventi',
        content: 'sezione contenente gli eventi.'
      }
    ]
  },
}
</script>

<style scoped>
.content-container {
  padding: 0;
}

@media (min-width: 768px) {
  .content-container {
    padding: 0 100px;
  }
}

.image-and-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  flex: 2; /* prende 2/3 dello spazio disponibile */
}

.image {
  flex: 1; /* prende 1/3 dello spazio disponibile */
  width: 100%;
}

img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-and-text {
    flex-direction: column;
  }

  .text, .image {
    width: 100%;
  }
}

p {
  font-size: 17px;
}
</style>
