<template>
  <NavBar/>
  <br>
  <router-view/>
  <Footer/>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'App',
  components: {
    Footer,
    NavBar
  },
  metaInfo: {
    title: 'Bilbo Family Foundation Italy',
    titleTemplate: '%s | Bilbo Family Foundation Italy',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'An example Vue application with vue-meta.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}

button {
  background-color : #DB0606;
  color : #fff;
  text-align : center;
  text-decoration : none;
  transition : background-color 0.5s ease 0s;
  border-radius : 5px;
  border : 0;
  cursor : pointer;
  display : inline-block;
  font-size : 16px;
  font-weight : 700;
  height : 50px;
  line-height : 50px;
  margin : 0 10px 10px 0;
  padding : 0 22px;
}
button:hover {
  background-color : #fc9700;
}

</style>
