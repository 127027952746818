<template>
  <div id="contact">
    <h2>Contatti</h2>
    <div>
      <div class="contact-info">
        <div class="contact-item">
          <h3>Email</h3>
          <a href="mailto:bilbofamilyfoundation@gmail.com">bilbofamilyfoundation@gmail.com</a>
          <br/>
          <a href="mailto:bilbofamilyfoundation@pec.it">bilbofamilyfoundation@pec.it</a>
        </div>
        <div class="contact-item">
          <h3>Telefono</h3>
          <a href="tel:3409358350">Mattia - 340 9358350</a><br/><br/>
          <a href="tel:3351251887">Patrizia - 335 1251887</a><br/><br/>
          <a href="tel:3461730588">Flavio - 346 1730588</a>
        </div>
        <div class="contact-item">
          <h3>Indirizzo</h3>
          <a href="https://goo.gl/maps/M6znzrsPK1YKuHhu5" target="_blank">Via Antonio Angiolini 25, Prato, PO</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  metaInfo: {
    title: 'Contatti',
    meta: [
      {
        vmid: 'contatti',
        name: 'contatti',
        content: 'sezione contatti per contattare la fondazione.'
      }
    ]
  }
}
</script>

<style scoped>
#contact {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contact-item {
  width: 200px;
  margin: 10px;
}

.contact-item a {
  color: #000;
}

.social-media {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.social-media a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.social-media a img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
