<template>
  <div id="progetti">
    <h2>
      Progetti</h2>
    <div class="project-list">
      <div v-for="(project, index) in projects.filter(p => p.achieved || p.inProgress)" :key="index" class="project-card">
        <div v-if="index < 15">
          <h3>{{ project.title }}</h3>
          <p>{{ project.text.slice(0,50) }}...</p>
          <button @click="openProjectDetails(project)">Dettagli</button>
        </div>
      </div>
      <div v-if="projects.length === 0" class="empty-message">
        Nessun progetto disponibile al momento.
      </div>
    </div>

    <br>
    <h2>Progetti futuri</h2>
    <div class="project-list">
      <div v-for="(project, index) in projects.filter(p => !p.achieved && !p.inProgress)" :key="index" class="project-card">
        <div v-if="index < 15">
          <h3>{{ project.title }}</h3>
          <p>{{ project.text.slice(0,50) }}...</p>
          <button @click="openProjectDetails(project)">Dettagli</button>
        </div>
      </div>
      <div v-if="projects.length === 0" class="empty-message">
        Nessun progetto disponibile al momento.
      </div>
    </div>

    <div v-if="selectedProject" class="popup">
      <div class="popup-content">
        <h3>{{ selectedProject.title }}</h3>
        <p>{{ selectedProject.text }}</p>
        <h4 v-if="selectedProject.achieved">Obiettivo raggiunto</h4>
        <h4 v-if="selectedProject.inProgress">Stiamo lavorando su questo obbiettivo</h4>
        <p v-if="selectedProject.achievement_date">Data di raggiungimento: {{ formatDate(selectedProject.achievement_date) }}</p>
        <button @click="closePopup">Chiudi</button>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs, limit, query, orderBy } from "firebase/firestore";
import { db } from "@/main";

export default {
  name: "ProjectsComponent",
  metaInfo: {
    title: "Progetti",
    meta: [
      {
        vmid: "Progetti",
        name: "Progetti",
        content: "Sezione Progetti per vedere gli obiettivi della fondazione.",
      },
    ],
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
    };
  },
  async mounted() {
    const projectsCollection = collection(db, "projects");
    const queryProjects = query(projectsCollection, orderBy("achieved", "desc"), limit(15));
    const querySnapshot = (await getDocs(queryProjects));
    this.projects = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      data.achievement_date = data.achievement_date? new Date(data.achievement_date) : null
      return data;
    });
  },
  methods: {
    openProjectDetails(project) {
      this.selectedProject = project;
    },
    closePopup() {
      this.selectedProject = null;
    },
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    }
  },
};
</script>

<style scoped>
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.project-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.empty-message {
  text-align: center;
  color: #888;
  margin-top: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  text-align: center;
}

.popup-content button {
  margin-top: 10px;
}

@media (max-width: 700px) {
  .popup-content {
    max-width: 400px;
  }
}
</style>
