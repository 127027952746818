<template>
  <div>
    <nav>
      <div class="logo">
        <img @click="$router.push('/')" style="width: 60px; cursor: pointer" src="@/assets/BBFILogo.jpg" alt="Logo">
      </div>
      <ul v-if="!isMobile">
        <li><a @click="smoothScrollTo('chi-siamo')">Chi siamo</a></li>
        <li><a @click="smoothScrollTo('eventi')">Eventi</a></li>
        <li><a @click="smoothScrollTo('progetti')">Progetti</a></li>
        <li><a @click="smoothScrollTo('come-aiutare')">Cosa puoi fare</a></li>
        <li><a @click="smoothScrollTo('contact')">Contatti</a></li>
      </ul>
      <a class="hamburger-button" v-if="isMobile" @click="toggleMenu">
        ☰
      </a>
      <ul class="menu" v-show="menuOpen && isMobile">
        <li><a @click="smoothScrollTo('chi-siamo')">Chi siamo</a></li>
        <li><a @click="smoothScrollTo('eventi')">Eventi</a></li>
        <li><a @click="smoothScrollTo('progetti')">Progetti</a></li>
        <li><a @click="smoothScrollTo('come-aiutare')">Cosa puoi fare</a></li>
        <li><a @click="smoothScrollTo('contact')">Contatti</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      menuOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    smoothScrollTo (id) {
      if (this.$route.name !== 'Home') {
        this.$router.push('/')
        setTimeout(() => {
          window.scrollTo({
            top: document.getElementById(id).offsetTop - 100,
            behavior: 'smooth'
          });
        }, 100)
      } else {
        window.scrollTo({
          top: document.getElementById(id).offsetTop - 100,
          behavior: 'smooth'
        });
      }
      this.menuOpen = false;
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth < 600
    }
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
nav {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: white;
}

.logo {
  float: left;
  padding-top: 5px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin-right: 10px;
  margin-top: 7px;
  margin-bottom: 0;
  float: right;
}

nav li {
  display: inline-block;
}

nav a {
  color: #000;
  display: block;
  padding: 1em;
  text-decoration: none;
  transition: 0.5s;
}

nav a:hover {
  background-color: #dadada;
}

.hamburger-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: none;
  color: black;
  background-color: white;
  font-size: 25px;
  cursor: pointer;
}


@media (max-width: 600px) {
  nav li,
  .logo {
    float: none;
    text-align: left;
  }
  nav a {
    padding: 10px;
    font-size: 16.2px;
  }
  .hamburger-button {
    right: 15px;
  }
  ul.menu{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ul.menu li{
    position: relative;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);

  }
}

</style>
