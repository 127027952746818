<template>
  <div id="come-aiutare">
    <h2>Cosa puoi fare</h2>
    <div class="content-container">
      <div class="image-and-text">
          <span class="image-container">
              <img class="help-image" src="@/assets/how-can-you-help.jpg" alt="Image of foundation stitching" />
          </span>
        <div class="text">
          <h4>Diventare donatore mensile</h4>
          <p>
            La nostra associazione si basa sulle donazioni mensili dei fondatori e dei donatori mensili,<br>
            la quota pensata è quella simbolica di 10€ al mese ma ognuno può contribuire in <br>
            base alle proprie disponibilità.<br><br>
            Abbiamo un gruppo Whatsapp dove i donatori vengono<br>
            resi partecipi delle iniziative, aggiornati sui progressi e
            <br>sulle transazioni di denaro e coinvolti attivamente nelle <br>decisioni prese dai fondatori. <br><br>
            Se interessati potete scriverci attraverso i contatti <br>riportati su questo sito o sui nostri social.
          </p>

          <h4>Volontariato e aiuto pratico</h4>

          <p>Siamo più che propensi ad accettare un aiuto per l’organizzazione<br>
            dei nostri eventi
            e in tutte le attività che quotidianamente richiedono<br> la nostra attenzione e il nostro tempo.<br>
            Anche in questo caso potete scriverci attraverso i contatti del sito o i nostri social.</p>

          <h3>Donazioni singole o sporadiche
            <br>
            <br>
            Iban: IT86O0306909606100000197390 <br>
            Intestatario: Bilbo Family Foundation Italy <br>
            Banca: Intesa San Paolo <br>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoundationStitching",
  metaInfo: {
    title: 'Cosa puoi fare',
    meta: [
      {
        vmid: 'come puoi aiutare',
        name: 'come puoi aiutare',
        content: 'sezione su come puoi aiutare la fondazione nei suoi progetti e scopi.'
      }
    ]
  },
}
</script>

<style scoped>
.content-container {
  padding: 0;
}

.image-and-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image-container {
  width: 50%;
  height: 600px;
  overflow: hidden;
}

.help-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-and-text {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .content-container {
    padding: 0 100px;
  }
}

.text p {
  font-size: 17px;
}
</style>
