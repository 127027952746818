<template>
  <div id="chi-siamo">
    <h2>Chi siamo</h2>
    <div class="people-list">
      <div class="person" :key="index" v-for="(person, index) in people">
        <img :src="avatars[index]" :alt="person.name" class="person-image" />
        <h3>{{ person.name }}</h3>
        <p>{{ person.bio }}</p>
      </div>
    </div>
    <div>
      <router-link to="/organization">
        <button>La nostra storia</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre",
  metaInfo: {
    title: 'Chi siamo',
    meta: [
      {
        vmid: 'chi siamo',
        name: 'chi siamo',
        content: 'sezione chi siamo del sito, contenente informazioni sui membri della fondazione.'
      }
    ]
  },
  data () {
    return {
      avatars: [
        require("@/assets/people/mattia_mazzeo.jpeg"),
        require("@/assets/people/giulia_pecchioli.jpg"),
        require("@/assets/people/eleonora_faggi.jpg"),
        require("@/assets/people/patrizia_pieraccini.jpg"),
        require("@/assets/people/tommaso_salvestrini.jpg"), //tommaso salvestrini
        "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg", //flavio melani
        require("@/assets/people/eleonora_bua.jpg"),
      ],
      people: [
        {
          name: "Mattia Mazzeo",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Presidente e Rappresentante legale"
        },
        {
          name: "Giulia Pecchioli",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Vice-presidente e Cofondatore"
        },
        {
          name: "Eleonora Faggi",
          bio: "Segretario e Cofondatore"
        },
        {
          name: "Patrizia Pieraccini",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Consigliere e Cofondatore"
        },
        {
          name: "Tommaso Salvestrini",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Consigliere e Cofondatore"
        },
        {
          name: "Flavio Melani",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Consigliere e Cofondatore"
        },
        {
          name: "Eleonora Bua",
          image: "https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg",
          bio: "Consigliere e Cofondatore"
        }
      ]
    }
  }
}
</script>

<style scoped>
.people-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 900px;
  margin: 0 auto;
}

.person {
  flex-basis: 200px;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 10px;
}

.person-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
</style>

